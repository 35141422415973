import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10rem 0;

  h1 {
    font-size: 40pt;
    font-weight: normal;
  }

  h4 {
    font-size: 16pt;
    color: #6a6a6a;
    font-weight: normal;
  }

  p {
    font-size: 12pt;
    color: #6a6a6a;
    line-height: 1.8;
    max-width: 90%;
  }
`;

export default Wrapper;
