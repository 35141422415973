import React from 'react';
import { FormattedMessage } from 'react-intl';
import Container from '../../../common/Container';
import Wrapper from './styles';

export default () => (
  <Wrapper as={Container}>
    <h1>
      <FormattedMessage id="signupsuccess_title" />
    </h1>
    <p>
      <FormattedMessage id="signupsuccess_subtitle" />
    </p>
    <p>
      <FormattedMessage id="signupsuccess_text" />
    </p>
  </Wrapper>
);
