import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/common/Layout';
import SignupSuccess from '../../components/from/SignUp/SignUpSuccess';

const SignupSuccessPage = () => (
  <Layout lang="de">
    <SEO
      title="Registration erfolgreich - Nextcloud Hosting"
      description="Wir erstellen nun deine Cloud."
    />
    <SignupSuccess lang="de" />
  </Layout>
);

export default SignupSuccessPage;
